import { createRouter, createWebHistory } from "vue-router";
import HomeView from "@/views/Home/HomeView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/ciudad/historia",
    name: "historia",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/Ciudad/Historia/HistoriaView.vue"
      ),
  },
  {
    path: "/ciudad/luque-hoy",
    name: "luque-hoy",
    component: () => import("../views/Ciudad/Hoy/LuqueHoyView.vue"),
  },
  {
    path: "/ciudad/descubri-luque",
    name: "descubri-luque",

    component: () => import("../views/Ciudad/Descubri/DescubriLuqueView.vue"),
  },
  {
    path: "/ciudad/descubri-luque/:lugar",
    name: "descubri-luque-lugares",

    component: () => import("../views/Ciudad/Descubri/LugaresView.vue"),
  },
  {
    path: "/ciudad/fiesta-nacional-familia-piemontesa",
    name: "fiesta-nacional-familia-piemontesa",

    component: () => import("../views/Ciudad/Fiesta/FiestaPiemontesaView.vue"),
  },
  {
    path: "/ciudad/festejos-populares",
    name: "fiesta-nacional",

    component: () => import("../views/Ciudad/Fiesta/FiestaNacionalView.vue"),
  },
  {
    path: "/gobierno/gestion/modernizacion",
    name: "gestion-modernizacion",

    component: () =>
      import("../views/Gobierno/Programas/ModernizacionView.vue"),
  },
  {
    path: "/gobierno/quienes-somos",
    name: "quienes-somos",

    component: () => import("../views/Gobierno/Quienes-Somos/GobiernoView.vue"),
  },
  {
    path: "/gobierno/gestion",
    name: "gestion",

    component: () => import("../views/Gobierno/Gestion/GestionView.vue"),
  },
  {
    path: "/gobierno/logros",
    name: "logros",

    component: () => import("../views/Gobierno/Logros/LogrosView.vue"),
  },
  {
    path: "/gobierno/transparencia-municipal",
    name: "transparencia",

    component: () =>
      import("../views/Gobierno/Transparencia/TransparenciaMunicipalView.vue"),
  },

  //se muestran todas las categorias TRAMITES DE SERVICIOS
  {
    path: "/tramites-servicios/guia-tramites",
    name: "tramites-servicios",

    component: () =>
      import(
        "../views/Tramites - Servicios/Guia-tramites/GuiaTramitesView.vue"
      ),
  },
  {
    path: "/tramites-servicios/pagar-tasas",
    name: "pagar-tasas",

    component: () =>
      import("../views/Tramites - Servicios/Pagar-Tasas/PagarTasasView.vue"),
  },
  //vista para los requisitos de los trmaites de transito
  {
    path: "/tramites-servicios/guia-tramites/transito",
    name: "guia-tramites-transito",

    component: () =>
      import("../views/Tramites - Servicios/Transito/TransitoView.vue"),
  },
  {
    path: "/tramites-servicios/guia-tramites/transito/multas",
    name: "guia-tramites-transito-multas",

    component: () =>
      import("../views/Tramites - Servicios/Transito/MultasView.vue"),
  },
  //vista de los tramites de cada categoria
  {
    path: "/tramites-servicios/guia-tramites/:categoria/:id",
    name: "guia-tramites",

    component: () =>
      import(
        "../views/Tramites - Servicios/Tramites/TramitesCategoriasView.vue"
      ),
  },
  //vista del portal tributario
  {
    path: "/tramites-servicios/guia-tramites/tasas-e-impuestos",
    name: "guia-tramites-tasas-e-impuestos",

    component: () =>
      import(
        "../views/Tramites - Servicios/TasasImpuestos/TasasImpuestosView.vue"
      ),
  },
  {
    path: "/tramites-servicios/guia-tramites/catastro-y-obras",
    name: "guia-tramites-catastro-y-obras",

    component: () =>
      import("../views/Tramites - Servicios/Catastro/ObrasCatastroView.vue"),
  },

  {
    path: "/novedades",
    name: "novedades",

    component: () => import("../views/Novedades/NovedadesView.vue"),
  },

  {
    path: "/novedades/:titulo/:id",
    name: "VerNoticiaView",

    component: () => import("../views/Novedades/VerNoticiaView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
