<template>
  <div class="eco-contenedor" id="caja">
    <div class="medio">
      <div>
        <img src="@/assets/images/EcoLogoMobile.svg" alt="" class="logo" />
      </div>
      <div class="text">
        <h3 style="color: #fff">¡POR UN PLANETA MÁS VERDE!</h3>

        <p id="descripcion" style="color: #fff">
          La digitalización es la clave para proteger nuestro planeta. Al
          reducir el uso de papel y adoptar tecnologías ecoamigables podemos
          hacer del mundo un lugar más verde y limpio.
        </p>
      </div>
      <div @click="toggleContent" class="flecha">
        <img :src="imagenFlecha" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "CardEcoComponent",
  created() {
    this.imagenFlecha =
      "https://d24g0foqntlzx0.cloudfront.net/icons/homeIcons/Flecha+Abajo.svg";
  },
  methods: {
    toggleContent() {
      var caja = document.getElementById("caja");
      var content = document.getElementById("descripcion");
      if (content.style.display === "none") {
        content.style.display = "block";
        caja.style.height = "auto";
        this.imagenFlecha =
          "https://d24g0foqntlzx0.cloudfront.net/icons/homeIcons/Flecha+Arriba.svg";
      } else {
        content.style.display = "none";
        caja.style.height = "193px";
        this.imagenFlecha =
          "https://d24g0foqntlzx0.cloudfront.net/icons/homeIcons/Flecha+Abajo.svg";
      }
      // content.innerHTML =
      //   "La digitalización es la clave para proteger nuestro planeta. Al reducir el uso de papel y adoptar tecnologías ecoamigables podemoshacer del mundo un lugar más verde y limpio.";
      // caja.classList.toggle("expanded");
      // var leer = document.getElementById(`leer${this.data.titulo}`);
      // leer.innerHTML === "Leer más"
      //   ? (leer.innerHTML = "Leer menos")
      //   : (leer.innerHTML = "Leer más");
      // var contentdos = document.getElementById(`${this.index}`);

      // contentdos.classList.toggle("expanded");
      // content.classList.toggle("expanded");
    },
  },
  data() {
    return {
      imagenFlecha: "",
    };
  },
};
</script>
<style scoped>
.eco-contenedor {
  border-radius: 15.178px;
  background: var(--Verde-agua, #00c3a8);
  color: white;
  /* height: 5%; */
  height: 134px;
  width: 967px;
  margin: auto;
  padding: 25px;
  margin-top: 30.36px;
  margin-bottom: 60px;
}
.medio {
  height: auto;
  margin: auto;
  display: flex;
  gap: 25px;
  align-items: center;
}
.logo {
  width: 83px;
  height: 83px;
  flex-shrink: 0;
  /* margin-left: 16%; */
}
.text {
  text-align: left;
  /* width: 80%; */
}
svg {
  display: none;
}
.text p {
  color: var(--blanco, #fff);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.text h3 {
  color: var(--blanco, #fff);
  font-family: Montserrat;
  font-size: 21.9px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}
svg {
  display: none;
}
.expanded {
  height: auto;
  /* width: 667px; */
  /* display: contents; */
}
.flecha {
  display: none;
}
@media (max-width: 1360px) {
  .eco-contenedor {
    width: 70%;
    height: auto;
  }
}
@media (max-width: 770px) {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 0;
  }
  .eco-contenedor {
    height: 193px;
    margin: auto;
    width: 326px;
    padding: 0px;
    margin-top: 21px;
    margin-bottom: 21px;
  }
  .medio {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* text-align: center; */
    gap: 5px;
    padding: 19px 32px 14px 27px;
  }
  .text {
    /* height: 46px; */
    width: 100%;
    text-align: center;
  }
  .text h3 {
    font-size: 19.063px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
  }
  .text p {
    display: none;
    font-size: 11px;
  }
  .logo {
    height: 72px;
    width: 72px;
  }
  svg {
    display: block;

    /* margin-top: 15px; */
  }
  .expanded {
    height: auto;
    /* width: 667px; */
    display: contents;
  }
  .flecha {
    display: block;
  }
  .flecha img {
    width: 28px;
    height: 17px;
  }
  /* .medio {
    width: 100%;
    height: 62px;
    justify-content: left;
    gap: 14px;
  }
  .text {
    padding: 0% 3%;
    height: 62px;
    width: 95%;
  }
  .text h3 {
    font-weight: 700;
    font-size: 10px;
    margin-bottom: -0.5%;
  }
  .text p {
    font-size: 8px;
    font-weight: 400;
  }
  .logo {
    height: 45px;
    width: 45px;
    margin-bottom: 15%;
  }
  .eco-contenedor {
    width: 90%;
    margin: auto;
    margin-top: 3%;
    border-radius: 8px;
  } */
}
</style>
