<template>
  <div class="botonFiesta">
    <a href="/ciudad/fiesta-nacional-familia-piemontesa">
      <img
        src="./../../assets/images/FiestaPiemontesa.svg"
        alt=""
        class="imagenFiesta"
      />
    </a>
  </div>
</template>
<script>
export default {
  name: "FiestaPiemontesaPublicidad",
};
</script>
<style scoped>
.botonFiesta {
  background: #ff2745;
  display: flex;
  flex-direction: row;
  position: fixed;
  align-items: center;
  right: 0px;
  top: 225px;
  z-index: 1;
  width: 99px;
  border-radius: 14.667px 0px 0px 14.667px;
  height: 64px;
  justify-content: center;
}
.imagenFiesta {
  width: 74px;
  height: 44px;
  flex-shrink: 0;
}
a {
  text-decoration: none;
}
</style>
